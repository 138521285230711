<template>
  <div>
    <titulo-perfis :podeCadastrar="podeCadastrar" @cadastrar="openModal" />

    <b-table
      :fields="table.fields"
      :items="table.items"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      show-empty
      responsive
      striped
      hover
    >
      <template #cell(acoes)="item" v-if="mostrarAcoes">
        <b-dropdown variant="outline-default">
          <b-dropdown-item
            @click="irPara('perfil-permissao', item.item.id)"
            v-if="podeEditar"
          >
            {{ $t('GERAL.GERENCIAR_PERMISSOES') }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="openModal('perfil', item.item)"
            v-if="podeEditar"
          >
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <b-dropdown-item @click="excluir(item.item)" v-if="podeExcluir">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <paginacao v-model="paginacao" :total="paginacao.total" @input="listar" />

    <modal-cadastrar-editar
      :exibir="modais.perfil"
      :item="modais.perfilDados"
      @atualizar="listar"
      @fechar="closeModal('perfil')"
    />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';
import rotas from '@/common/utils/rotas';

// Services:
import PerfisService from '@/common/services/perfis/perfis.service';

// Components:
import ModalCadastrarEditar from '@/views/acessos/perfis/components/Modal';
// import { Cabecalho } from '@/components/headers/index';
import Paginacao from '@/components/paginacao/Paginacao';
import Permisoes from '@/components/mixins/permissoes';
import TituloPerfis from '@/views/acessos/perfis/components/TituloPerfis.vue';

export default {
  name: 'PerfisIndex',
  mixins: [Permisoes],
  components: {
    TituloPerfis,
    // Cabecalho,
    Paginacao,
    ModalCadastrarEditar,
  },
  data() {
    return {
      table: {
        items: [],
        fields: [
          {
            key: 'nome',
            label: this.$t('PERFIS.NOME'),
          },
          {
            key: 'acoes',
            label: this.$t('GERAL.ACOES'),
          },
        ],
      },
      item: {},
      paginacao: {
        total: 0,
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
      },
      modais: {
        perfil: false,
        perfilDados: {},
      },
    };
  },
  computed: {
    mostrarAcoes() {
      return this.podeEditar || this.podeExcluir;
    },
  },
  mounted() {
    this.listar();
  },
  methods: {
    // FUNÇÕES CRUD:
    listar() {
      this.$store.dispatch(START_LOADING);
      PerfisService.listar(this.paginacao)
        .then((result) => {
          this.table.items = result.data.itens;
          this.paginacao.total = result.data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.erro(this.$t('GERAL.ERRO'), err.response.data.errors);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    excluir(item) {
      mensagem
        .confirmacao(
          null,
          this.$t('PERFIS.CONFIRMAR_EXCLUIR').formatUnicorn({
            nome: item.nome,
          })
        )
        .then(async (result) => {
          if (result.value) {
            this.$store.dispatch(START_LOADING);
            PerfisService.excluir(item.id)
              .then(() => {
                mensagem.sucesso(
                  this.$t('GERAL.SUCESSO'),
                  this.$t('PERFIS.PERFIL_EXCLUIDO_SUCESSO')
                );
                this.listar();
              })
              .catch((err) => {
                mensagem.showErrors(err);
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
