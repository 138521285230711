<template>
  <modal
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <div class="row">
      <div class="col-12">
        <input-text
          ref="nome"
          v-model="form.nome"
          :label="$t('PERFIS.NOME')"
          :maxLength="25"
          required
        />
      </div>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';

// Services:
import PerfisService from '@/common/services/perfis/perfis.service';

// Components:
import Modal from '@/components/modal/Modal';
import { InputText } from '@/components/inputs';
import { Perfil } from '@/common/models';

export default {
  name: 'ModalCadastrarEditar',
  components: {
    Modal,
    InputText,
  },
  props: {
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: Object },
  },
  data() {
    return {
      form: new Perfil({}),
      titulo: '',
    };
  },
  watch: {
    exibir: 'atualizaForm',
  },
  methods: {
    atualizaForm() {
      this.form = new Perfil(this.item);
      !this.form.id
        ? (this.titulo = this.$t('GERAL.CADASTRAR'))
        : (this.titulo = this.$t('GERAL.EDITAR'));
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    salvar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      PerfisService.salvar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('PERFIS.PERFIL_CADASTRADO_SUCESSO')
          );
          this.fecharModal();
          this.$emit('atualizar');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
